import React, { useState, useEffect } from "react";
import SearchBox from "features/Search/SearchBox";
import './DataCards.scss';

interface Column {
	field: string;
	headerName: string;
	valueGetter?: (row: any) => React.ReactNode;
	removeHeaderFromMobile?: boolean;
}

interface DataCardsProps {
	rows: Array<Record<string, any>>;
	columns: Column[];
	columnIdName: string;
}

const DataCards: React.FC<DataCardsProps> = ({ rows, columns, columnIdName }) => {
	const [filteredRows, setFilteredRows] = useState(rows);
	const [clearSearch, setClearSearch] = useState(false);

	// Update filteredRows whenever rows prop changes
	useEffect(() => {
		setFilteredRows(rows);
	}, [rows]);

	// Filter rows based on search input
	const handleKeyDown = (searchValue: string) => {
		setClearSearch(false);
		const lowerCaseSearch = searchValue ? searchValue.toLowerCase() : "";
		const filtered = rows.filter(row =>
			columns.some(col => {
				const cellContent = col.valueGetter ? col.valueGetter(row) : row[col.field];
				return typeof cellContent === 'string' && cellContent.toLowerCase().includes(lowerCaseSearch);
			})
		);
		setFilteredRows(filtered);
	};

	const renderCards = () => {
		return filteredRows.map((row) => (
			<div key={row[columnIdName]} className="data-card">
				{columns
					.filter((col) => {
						if (typeof col.headerName === 'string' && col.headerName.includes('remove-from-card')) {
							return false;
						}
						if (col.valueGetter && typeof col.valueGetter === 'function') {
							const valueContent = col.valueGetter(row);
							if (valueContent && typeof valueContent === 'object' && 'props' in valueContent && valueContent.props.className?.includes('remove-from-card')) {
								return false;
							}
						}
						return true;
					})
					.map((col) => {
						const valueContent = col.valueGetter ? col.valueGetter(row) : row[col.field];
						return (
							<div key={col.field} className="data-card-field">
								{!col.removeHeaderFromMobile && (
									<p><strong>{col.headerName}: </strong></p>
								)}
								{typeof valueContent === 'string' ? (
									<span dangerouslySetInnerHTML={{ __html: valueContent }} />
								) : (
									valueContent
								)}
							</div>
						);
					})}
			</div>
		));
	};

	return (
		<div className="data-cards-container">
			<SearchBox clear={clearSearch} placeholder="Search" handleKeyDown={handleKeyDown} />
			{filteredRows.length > 0 ? (
				renderCards()
			) : (
				<p className="no-results">No available options</p>
			)}
		</div>
	);
};

export default DataCards;
