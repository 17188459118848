import { useEffect, useState } from "react";
import { DataTable as PrTable } from "primereact/datatable";
import SearchBox from "features/Search/SearchBox";
import { Column } from "primereact/column";
import DataCards from "components/DataCards/DataCards";
import "./DataTable.scss";
import { MOBILE_WIDTH } from "../../utils/constants";

/**
 * Properties Object definition used in the DataTable component.
 * @typedef {Object} DataTableProps
 * @property {Array.<Object>} columns
 * @property {Array.<Object>} rows
 * @property {string} columnIdName
 * @property {Function} onRowClick
 * @property {number} [rowsPerPage=10] - Number of rows per page (optional, default is 10)
 * @property {boolean} [useCardView=false] - Whether to use the card view for mobile
 */

/**
 * @component
 * @param {DataTableProps} props
 */

const DataTable = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);
	const [clearSearch, setClearSearch] = useState(false);

	// Handle screen resize to detect mobile view
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
		// Set initial mobile state
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const renderHeader = () => {
		if (!props.filters) {
			return (<></>);
		}
		return (
			<SearchBox clear={clearSearch} placeholder="Search Timeline" handleKeyDown={props.handleFilter} />
		);
	};

	// Set default values for rows and columns to prevent null or undefined errors
	const rows = props.rows || [];
	const columns = props.columns || [];
	const { rowsPerPage = 10 } = props;
	const rowsPerPageOptions = [rowsPerPage, 25, 100].sort((a, b) => a - b);

	const tableProps = {
		value: rows,
		paginator: rows.length > rowsPerPage,
		filters: props.filters,
		globalFilterFields: props.globalFilterFields,
		expandedRows: props.expandedRows,
		onRowToggle: props.onRowToggle,
		rowExpansionTemplate: props.rowExpansionTemplate,
		removableSort: true,
		rows: rowsPerPage,
		rowsPerPageOptions: rowsPerPageOptions,
		header: renderHeader,
		paginatorTemplate: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
		currentPageReportTemplate: "{first} - {last} of {totalRecords}",
		rowClassName: props.rowClassName
	};

	if (props.onRowClick) {
		tableProps.onRowClick = props.onRowClick;
		tableProps.selectionMode = "single";
	}

	return (
		<div className={`data-table-container ${props.useCardView ? 'use-card-view' : ''}`}>
			{isMobile && props.useCardView ? (
				<DataCards rows={rows} columns={columns} columnIdName={props.columnIdName} />
			) : (
				<PrTable className="data-table" {...tableProps}>
					{columns.map((col, i) => (
						(col.expander)
							? (<Column key={col.field || i} expander={col.expander} headerClassName={col.class} style={col.style} />)
							: (
								<Column
									key={col.field || i}
									field={col.field}
									sortable={col.sortable !== undefined ? col.sortable : false}
									header={col.headerName}
									body={(rowData) => {
										if (col.valueGetter) {
											const cellContent = col.valueGetter(rowData);
											return typeof cellContent === 'string' ? (
												<span dangerouslySetInnerHTML={{ __html: cellContent }} />
											) : (
												cellContent
											);
										}
									}}
									headerClassName={col.class}
									style={col.style}
								/>)
					))}
				</PrTable>
			)}
		</div>
	);
};

export default DataTable;
