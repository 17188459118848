import { useCallback, useEffect, useState } from "react";

const usePlayerResize = (window, sidebarPresent) => {
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const [receivedPlayerHeight, setReceivedPlayerHeight] = useState();
	const [receivedPlayerWidth, setReceivedPlayerWidth] = useState();
	const [calculatedPlayerWidth, setCalculatedPlayerWidth] = useState("100%");
	const [calculatedPlayerHeight, setCalculatedPlayerHeight] = useState("100%");
	const [breadcrumbHeight, setBreadcrumbHeight] = useState(0);

	const detailsHeight = 140; // Area below the player with the navigation
	const breadcrumb = 70;
	const newMaxHeight = viewportHeight - (breadcrumb + detailsHeight);
		
	const calculatePlayerSize = useCallback(() => {
		const element = document.getElementById('player-container');

		// Detect if the viewport width is below 1024px (tablet/mobile view)
		if (viewportWidth < 1024) {
			// Mobile/Tablet view: always set width to "unset"
			setCalculatedPlayerWidth("unset");

			// Use requestAnimationFrame to ensure DOM has updated
			requestAnimationFrame(() => {
				if (element) {
					const computedWidth = element.offsetWidth; // Get the actual width from the DOM
					// Calculate height based on playerRatio and the actual computed width
					if (receivedPlayerWidth && receivedPlayerHeight) {
						const playerRatio = receivedPlayerHeight / receivedPlayerWidth; // Aspect ratio of the player
						const newHeight = computedWidth * playerRatio; // Calculate height using the player ratio (note it's computedWidth * playerRatio)
						setCalculatedPlayerHeight(newHeight); // Set the new height
					}
					else {
						console.debug("newMaxHeight" + newMaxHeight);
						// Fallback if the player ratio is not available
						setCalculatedPlayerHeight(newMaxHeight);
						setCalculatedPlayerWidth("100%");
					}
				}
			});
		} else {
			// Desktop view: set height to 100vh and calculate the width based on the aspect ratio
			const menu = sidebarPresent ? 430 : 0;
			const sidePadding = 60; // At least 30px each side
			const newMaxWidth = viewportWidth - (menu + sidePadding);

			if (receivedPlayerWidth && receivedPlayerHeight) {
				const playerRatio = receivedPlayerHeight / receivedPlayerWidth; // Aspect ratio of the player

				// Set height to avaliable viewport height
				let newHeight = newMaxHeight; 

				// Calculate new width based on the 100vh height and player ratio
				let newWidth = newHeight / playerRatio;

				// If the new width exceeds the max width, adjust width and height accordingly
				if (newWidth > newMaxWidth) {
					newWidth = newMaxWidth;
					newHeight = newWidth * playerRatio; // Recalculate the height based on the new width
				}

				// Set the calculated width and height
				setCalculatedPlayerWidth(newWidth);
				setCalculatedPlayerHeight(newHeight);
				console.debug("W:" + newWidth + " H:" + newHeight);
			}
			else {
				console.debug("Fallback: newMaxHeight" + newMaxHeight);
				setCalculatedPlayerHeight(newMaxHeight);
				setCalculatedPlayerWidth("100%");
			}
		}
	}, [receivedPlayerWidth, receivedPlayerHeight, viewportHeight, viewportWidth, sidebarPresent]);

	// Calculating Player Dimensions
	useEffect(() => {
		calculatePlayerSize();
	}, [receivedPlayerWidth, receivedPlayerHeight, viewportHeight, viewportWidth, breadcrumbHeight, calculatePlayerSize, calculatedPlayerWidth]);

	// Effect for window resizing.
	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight);
			setViewportWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [window]);

	return {
		setReceivedPlayerWidth,
		setReceivedPlayerHeight,
		setBreadcrumbHeight,
		calculatedPlayerWidth,
		calculatedPlayerHeight
	}
};

export default usePlayerResize;