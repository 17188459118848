import { Button, ButtonType } from "components/Button/Button";
import "components/Modal/Modal.scss";
import { ActionType } from "../ActionType";

const ConfirmationContent = ({ action, items = null, handleConfirm, deviceGroup = null }) => {
	const getMessageText = () => {
		const messageMap = {
			[ActionType.Add]: <>Are you sure you want to <span>add</span> the following user?</>,
			[ActionType.Update]: <>Are you sure you want to <span>update</span> the following user{items.length > 1 ? 's' : ''}?</>,
			[ActionType.Delete]: <>Are you sure you want to <span>delete</span> the following user{items.length > 1 ? 's' : ''}?</>,
			[ActionType.Resend]: <>Are you sure you want to <span>resend the registration email</span> to the following user{items.length > 1 ? 's' : ''}?</>,
			[ActionType.DeleteGroup]: items.length > 0
				? <>There {items.length > 1 ? 'are' : 'is'} {items.length} user{items.length > 1 ? 's' : ''} assigned to the device group <span>{deviceGroup?.DeviceGroupName}</span>. The following user{items.length > 1 ? 's' : ''} will be unassigned from it if you delete it. Are you sure?</>
				: <> Are you sure you want to <span>delete</span> the device group <span>{deviceGroup?.DeviceGroupName}</span>?</>
		}
		return (messageMap[action]) ? messageMap[action] : <></>;
	};

	const getUserText = (user) => {
		const fullName = (user.FullName) ? user.FullName : user.firstName + " " + user.lastName;
		return (action === ActionType.Add) ? fullName : fullName + " (" + (user.Username || user.username) + ")";
	}
	
	return (
		<div className="confirmation-message">
			<p>{getMessageText()}</p>
			{items.length > 0 &&
				<ul className="message-user-list">
					{items.map(item => (
						<li key={item.Id} className="message-user-list-item">{getUserText(item)}</li>
					))}
				</ul>
			}
			<div className="modal-buttons">
				<Button type={ButtonType.Secondary} text="No" onClick={() => handleConfirm(false)} />
				<Button type={ButtonType.Primary} text="Yes" onClick={() => handleConfirm(true, items)} />
			</div>
		</div>
	);
};

export default ConfirmationContent;
